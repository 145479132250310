import Vue from 'vue'
import App from './App.vue'
import { router, vuetify, i18n } from './plugins'
import { api } from 'ui-common'

Vue.prototype.window = window
Vue.prototype.translate = (
  nonNormalizedText: string,
  options: {
    [key: string]: any;
  } | undefined
) => {
  const normalizedText = nonNormalizedText
    .trim()
    .replace(/[\s\r\t\n]+/g, ' ')
    .replace(/'/g, '-')
  if (options && options.gender !== undefined) {
    return i18n.tc(normalizedText, options.gender, options)
  }
  return i18n.t(normalizedText, options)
}
Vue.config.productionTip = false
Vue.config.errorHandler = function (error, vm, info) {
  const report = {
    report: JSON.stringify({
      info,
      error: error.toString()
    }),
    referrer: vm.$route.fullPath
  }
  api.$post('/js-error-logs', report)
}

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
