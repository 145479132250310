var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({},'v-card',_vm.$attrs,false),[(_vm.sliderOnly || _vm.totalLen < 20)?_c('v-card-text',[_c('v-slider',{attrs:{"value":_vm.value,"min":_vm.fields.length
        ? (_vm.dontKnow ? -1 : 0)
        : (_vm.dontKnow ? _vm.min - 1 : _vm.min),"max":(_vm.fields.length ? _vm.fields.length - 1 : _vm.max),"thumb-label":_vm.fields.length
        ? false
        : ((_vm.dontKnow && (_vm.value === (_vm.min - 1))) ? false : 'always'),"always-dirty":_vm.fields.length
        ? (_vm.dontKnow ? (_vm.value === -1 ? false : true) : true)
        : (_vm.dontKnow ? (_vm.value === _vm.min - 1 ? false : true) : true),"tick-labels":_vm.fields.length
        ? (_vm.dontKnow ? ["Don't know"].concat(_vm.fields) : _vm.fields)
        : [],"disabled":_vm.disabled,"ticks":"always","hide-details":""},on:{"change":function (value) { return _vm.$emit('input', value); }}}),_c('v-subheader',{staticClass:"pl-3"},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.fields.length ? '' : (_vm.value==_vm.min-1 ? " : Don't know" : ''))+" ")])])])],1):_c('v-card-text',[_c('v-select',{attrs:{"value":_vm.value,"label":_vm.title,"items":_vm.fields.map(function (val, idx) { return ({ label: val, value: idx }); }),"item-text":"label","item-value":"value","disabled":_vm.disabled,"outlined":"","hide-details":""},on:{"change":function (value) { return _vm.$emit('input', value); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }