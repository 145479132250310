<template>
  <v-card
    v-bind="$attrs"
    class="pp-select"
  >
    <v-card-title class="pp-select__title body-2">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-select
        :value="value"
        :items="items"
        item-text="label"
        item-value="value"
        :disabled="disabled"
        dense
        outlined
        hide-details
        @change="value => $emit('input', value)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    value: {
      type: [String, Number],
      default: '0'
    },
    title: {
      type: String,
      default: ''
    },
    dontKnow: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    min: {
      type: [String, Number],
      default: '0'
    },
    max: {
      type: [String, Number],
      default: '100'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const items = computed(() => {
      if (props.fields.length) {
        return props.fields
          .map((val, idx) => {
            if (val && typeof val === 'object') {
              return {
                label: val.text,
                value: idx
              }
            }

            return {
              label: val,
              value: idx
            }
          })
      }

      return Array(props.max - props.min + 1).fill()
        .map((_, idx) => (props.min + idx))
    })

    return {
      items
    }
  }
}
</script>

<style lang="scss">
.pp-select {
  .pp-select__title {
    word-break: break-word;
  }
}
</style>
