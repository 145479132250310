import {
  ref,
  computed,
  Ref,
  ComputedRef
} from '@vue/composition-api'
import {
  PageContactInfo,
  PagePersonalInfo,
  PageInsurance,
  Page2ndInsurance,
  PageEmployer,
  PageContacts
} from '@/constants'
import { auth, toastr, validators, VueI18nManager } from 'ui-common'
import repositories from '@/repositories'
import { useQuestionnaire } from './questionnaire'
import { logout } from 'ui-common/lib/auth'

export const loadingProfile = ref(false)

export const parentProfile: Ref<any> = ref(null)
export const childProfile: Ref<any> = ref(null)

export const primaryProfile: ComputedRef<any> =
  computed(() => parentProfile.value)
export const secondaryProfile: ComputedRef<any> =
  computed(() => childProfile.value || parentProfile.value)

export const primaryInsuranceContacts: Ref<any> = ref({})
export const secondaryInsuranceContacts: Ref<any> = ref({})

const loadingContacts = ref(false)
const contacts: Ref<any> = ref(null)

export const useContacts = () => {
  const getPatientContacts = async () => {
    const { patientid } = secondaryProfile.value

    loadingContacts.value = true
    try {
      const res = await repositories.contacts
        .getContactTypesByPatient({ patientid })
      contacts.value = res.data
        .map((contact: any) => {
          const { docId, contactName, patientContact } = contact
          let candidates: any[] = []

          if (docId) {
            candidates = [{
              id: docId,
              name: contactName
            }]
          }

          if (patientContact && patientContact.phone) {
            patientContact.phone = validators
              .formatPhoneNumber(patientContact.phone)
          }

          return {
            ...contact,
            keyword: contactName,
            candidates,
            patientContact
          }
        })
    } catch (err) {} finally {
      loadingContacts.value = false
    }
  }

  const updatePatientContacts = async () => {
    const { patientid } = secondaryProfile.value

    let docsleep = null
    let docpcp = null
    let docdentist = null
    let docent = null
    let docmdother = null

    await Promise.all(contacts.value.map(async (contact: any) => {
      if (contact.patientContact) {
        if (contact.patientContact.id) {
          const { id } = contact.patientContact

          contact.patientContact = (await repositories.contacts
            .updatePatientContacts({
              id,
              contact: {
                ...contact.patientContact,
                phone: validators.formatPhoneNumber(
                  contact.patientContact.phone,
                  false
                )
              }
            })).data
        } else {
          const res = await repositories.contacts
            .createPatientContacts({
              contact: {
                ...contact.patientContact,
                phone: validators.formatPhoneNumber(
                  contact.patientContact.phone,
                  false
                )
              }
            })

          contact.patientContact = res.data
        }
      }

      const { typeId, docId } = contact
      const doctorID = contact.patientContact ? '' : docId

      switch (typeId) {
        case 1:
          docsleep = `${doctorID}`
          break
        case 2:
          docpcp = `${doctorID}`
          break
        case 3:
          docdentist = `${doctorID}`
          break
        case 4:
          docent = `${doctorID}`
          break
        case 5:
          docmdother = `${doctorID}`
          break
      }
    }))

    const res = await repositories.profile.updatePatientContacts({
      patientid,
      docsleep,
      docpcp,
      docdentist,
      docent,
      docmdother,
      lastRegSect: PageContacts
    })
    childProfile.value = res.data
  }

  const deletePatientContact = async ({ id }: any) => {
    await repositories.contacts.deletePatientContact({ id })
  }

  const getInsuranceContacts = async (isPrimary = true) => {
    const { patientid } = primaryProfile.value

    const res = await repositories.patientInsurances.getInsuranceContact({
      patientid,
      insType: isPrimary ? 1 : 2
    })

    if (isPrimary) {
      const {
        company,
        add1: address1,
        add2: address2,
        city,
        state,
        zip,
        phone1: phone,
        fax,
        email
      } = primaryProfile.value.insurancePrimaryContact || {}

      primaryInsuranceContacts.value = res.data[0] || {
        company,
        address1,
        address2,
        city,
        state,
        zip,
        phone,
        fax,
        email
      }
    } else {
      const {
        company,
        add1: address1,
        add2: address2,
        city,
        state,
        zip,
        phone1: phone,
        fax,
        email
      } = primaryProfile.value.insuranceSecondaryContact || {}

      secondaryInsuranceContacts.value = res.data[0] || {
        company,
        address1,
        address2,
        city,
        state,
        zip,
        phone,
        fax,
        email
      }
    }
  }

  const updateInsuranceContacts = async (insContacts: any) => {
    const { patientid } = primaryProfile.value

    const contactData = insContacts.type === 1
      ? primaryInsuranceContacts : secondaryInsuranceContacts

    if (contactData.value.id) {
      const res = await repositories.patientInsurances
        .updateInsuranceContact({
          id: contactData.value.id,
          info: { ...insContacts, patientid }
        })
      contactData.value = res.data || {}
    } else {
      const res = await repositories.patientInsurances
        .createInsuranceContact({ info: { ...insContacts, patientid } })
      contactData.value = res.data || {}
    }
  }

  const loadingContactCandidates = ref(false)
  const getContactCandidates = async ({ keyword, docid, idx }: any) => {
    loadingContactCandidates.value = true
    try {
      const res = await repositories.contacts
        .getContactCandidates({ keyword, docid })

      contacts.value[idx].candidates = res.data
        .map(({ contactid, firstname, lastname }: any) => ({
          id: contactid,
          name: [firstname, lastname].join(' ').trim()
        }))
        .filter(({ name }: any) => name)
    } catch (err) {} finally {
      loadingContactCandidates.value = false
    }
  }

  const saveContact = async (idx: number) => {
    const { patientid } = secondaryProfile.value

    const contact = contacts.value[idx]
    if (contact.patientContact) {
      if (contact.patientContact.id) {
        const { id } = contact.patientContact

        const res = await repositories.contacts
          .updatePatientContacts({
            id,
            contact: {
              ...contact.patientContact,
              phone: validators.formatPhoneNumber(
                contact.patientContact.phone,
                false
              )
            }
          })

        contact.patientContact = {
          ...res.data,
          phone: validators
            .formatPhoneNumber(res.data?.phone || '')
        }
      } else {
        const res = await repositories.contacts
          .createPatientContacts({
            contact: {
              ...contact.patientContact,
              phone: validators.formatPhoneNumber(
                contact.patientContact.phone,
                false
              )
            }
          })

        contact.patientContact = {
          ...res.data,
          phone: validators
            .formatPhoneNumber(res.data?.phone || '')
        }
      }
    }

    let docsleep = null
    let docpcp = null
    let docdentist = null
    let docent = null
    let docmdother = null

    contacts.value.map((contactValue: any) => {
      const { typeId, docId } = contactValue
      const doctorID = contactValue.patientContact ? '' : docId

      switch (typeId) {
        case 1:
          docsleep = `${doctorID}`
          break
        case 2:
          docpcp = `${doctorID}`
          break
        case 3:
          docdentist = `${doctorID}`
          break
        case 4:
          docent = `${doctorID}`
          break
        case 5:
          docmdother = `${doctorID}`
          break
      }
    })

    const { typeId, docId } = contact
    const doctorID = contact.patientContact ? '' : docId
    switch (typeId) {
      case 1:
        docsleep = `${doctorID}`
        break
      case 2:
        docpcp = `${doctorID}`
        break
      case 3:
        docdentist = `${doctorID}`
        break
      case 4:
        docent = `${doctorID}`
        break
      case 5:
        docmdother = `${doctorID}`
        break
    }

    const res = await repositories.profile.updatePatientContacts({
      patientid,
      docsleep,
      docpcp,
      docdentist,
      docent,
      docmdother,
      lastRegSect: PageContacts
    })
    childProfile.value = res.data
  }

  return {
    getPatientContacts,
    updatePatientContacts,
    deletePatientContact,
    getInsuranceContacts,
    updateInsuranceContacts,
    getContactCandidates,
    saveContact
  }
}

export const useProfile = () => {
  const {
    getPatientContacts,
    updatePatientContacts,
    getInsuranceContacts,
    updateInsuranceContacts,
    getContactCandidates,
    saveContact,
    deletePatientContact
  } = useContacts()

  const {
    qSections,
    loadingCompletedSections,
    getCompletedSections
  } = useQuestionnaire()

  const getProfile = async () => {
    try {
      loadingProfile.value = true
      const res = await repositories.profile.getProfile()

      parentProfile.value = res.data
        .find(({ parentPatientid }: any) => !parentPatientid)
      childProfile.value = res.data
        .find(({ parentPatientid }: any) => parentPatientid)

      if (
        parentProfile.value === undefined ||
        !parentProfile.value.usePatientPortal
      ) {
        const message = VueI18nManager
          .getInstance()
          .t('Error: This information is not associated with an active ' +
            'patient record. Please contact your provider if you believe ' +
            'this is an error.')
          .toString()
        toastr.removeByType('success')
        toastr.e(message)
        logout()
        return
      }
      auth.$state.profile = res.data

      await getCompletedSections()
      await getPatientContacts()
      await getInsuranceContacts()
      await getInsuranceContacts(false)
    } catch (err) {} finally {
      loadingProfile.value = false
    }
  }

  const lastRegSect = computed(() => secondaryProfile.value.lastRegSect)

  const updatingProfile = ref(false)

  const updateSecondaryProfile = async ({
    info,
    updatecontacts = false,
    insContact
  }: any) => {
    updatingProfile.value = true
    try {
      if (info.email && secondaryProfile.value.email !== info.email) {
        const { patientid } = parentProfile.value

        const oldEmail = parentProfile.value.email

        const res = await repositories.profile.updatePatientEmail({
          patientid,
          email: info.email
        })
        parentProfile.value = res.data

        await repositories.profile.updateEmail({
          patientid,
          oldEmail
        })
        const translator = VueI18nManager.getInstance()
        const emailUpdatedMessage = translator.t(
          'You have changed your email from {former} to {current}',
          { former: oldEmail, current: info.email }
        ).toString()
        const notificationSentMessage = translator.t(
          'You will be sent an email reminder of this change'
        ).toString()
        toastr.s(emailUpdatedMessage + '<br><br>' + notificationSentMessage)
      }

      if (!childProfile.value) {
        await getProfile()
      }

      if (childProfile.value) {
        const { patientid } = childProfile.value
        let res
        switch (info.lastRegSect) {
          case PageContactInfo:
            res = await repositories.profile.updatePatientContactInfo({
              patientid,
              ...info
            })
            break
          case PagePersonalInfo:
            res = await repositories.profile.updatePatientPersonalInfo({
              patientid,
              ...info
            })
            break
          case PageInsurance:
            res = await repositories.profile.updatePatientInsurance({
              patientid,
              ...info
            })
            break
          case Page2ndInsurance:
            res = await repositories.profile.updatePatientSecondaryInsurance({
              patientid,
              ...info
            })
            break
          case PageEmployer:
            res = await repositories.profile.updatePatientEmployer({
              patientid,
              ...info
            })
            break
          case PageContacts:
            res = await repositories.profile.updatePatientContacts({
              patientid,
              ...info
            })
            break
        }

        if (info.languageId) {
          try {
            res = await repositories.profile.updatePatientLanguage({
              patientid: childProfile.value.patientid,
              languageId: info.languageId
            })
          } catch (exception: any) {
            const message = VueI18nManager
              .getInstance()
              .t('Unable to update patient language.')
              .toString()
            toastr.e(message)
          }
        }

        childProfile.value = res.data
      } else {
        const data = { ...parentProfile.value }
        delete data.id
        delete data.letter
        delete data.template
        delete data.type
        delete data.contactTypeLabel
        delete data.sendMethod

        delete data.patientSummary
        delete data.parent
        delete data.patientInfo
        delete data.tx
        delete data.dateScheduled
        delete data.dateCompleted
        delete data.segment
        delete data.device
        delete data.applianceSince
        delete data.rxLomn
        delete data.total
        delete data.vob
        delete data.ledger
        delete data.lastSymptom
        delete data.lastSleepTest
        delete data.lastThorton
        delete data.lastTreatment
        delete data.lastHistory
        delete data.insurancePrimaryContact
        delete data.insuranceSecondaryContact
        delete data.doctor
        delete data.info

        const {
          patientid,
          docid,
          userid,
          ins2Dob,
          ...baseProfile
        } = data

        const ins2DobField = 'ins2_dob'

        const res = await repositories.profile.createProfile({
          ...baseProfile,
          [ins2DobField]: ins2Dob,
          patientid: null,
          parentPatientid: patientid,
          docid,
          userid: userid || docid,
          ...info
        })

        childProfile.value = res.data
      }

      if (updatecontacts) {
        await updatePatientContacts()
      }

      if (insContact && insContact.type) {
        await updateInsuranceContacts(insContact)
        const res = await repositories.profile.getPatientProfile({
          patientid: childProfile.value.patientid
        })
        childProfile.value = res.data
      }
    } catch (exception: any) {
      const errors = exception.response?.data?.errors || []
      if (exception.response.status === 422) {
        const messages =
          errors.map(({ message }: { message: string }) => message)
        const errorMessage = VueI18nManager
          .getInstance()
          .t('Something went wrong')
        messages.unshift(errorMessage)
        toastr.e(messages.join('<br>&#x2022; '))
      }
    } finally {
      updatingProfile.value = false
    }
  }

  return {
    primaryProfile,
    secondaryProfile,
    loadingProfile,
    getProfile,

    updatingProfile,
    updateSecondaryProfile,

    loadingContacts,
    contacts,
    saveContact,
    deletePatientContact,
    getPatientContacts,
    getContactCandidates,

    primaryInsuranceContacts,
    secondaryInsuranceContacts,

    lastRegSect,
    qSections,
    loadingCompletedSections
  }
}
