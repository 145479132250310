<template>
  <v-card
    v-bind="$attrs"
    class="mx-auto"
    width="440"
    dark
  >
    <v-card-text class="pt-8">
      <h1 class="text-center">
        {{ title }}
      </h1>
    </v-card-text>

    <v-divider />

    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Welcome to the Patient Portal'
    }
  }
}
</script>
