<template>
  <v-dialog
    v-model="dialog"
    max-width="300"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="grey"
          text
          @click="cancel"
        >
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn
          color="info"
          text
          @click="agree"
        >
          {{ $t('Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api'

export default {
  setup: () => {
    const state = ref({
      dialog: false,
      title: '',
      message: '',
      resolve: () => {},
      reject: () => {}
    })

    const open = ({ title, message }) => {
      state.value.dialog = true
      state.value.title = title
      state.value.message = message
      return new Promise((resolve, reject) => {
        state.value.resolve = resolve
        state.value.reject = reject
      })
    }
    const agree = () => {
      state.value.resolve(true)
      state.value.dialog = false
    }
    const cancel = () => {
      state.value.resolve(false)
      state.value.dialog = false
    }

    return {
      ...toRefs(state.value),
      agree,
      cancel,
      open
    }
  }
}
</script>
