import { apis } from 'ui-common'

export default {
  updateInsuranceContact: ({ id, info: { type, ...info } }: any) => apis.$put(
    `/patient-insurances/${id}`,
    {
      insurancetype: type,
      ...info
    }
  ),
  createInsuranceContact: ({ info: { type, ...info } }: any) => apis.$post(
    '/patient-insurances',
    {
      insurancetype: type,
      ...info
    }
  ),
  getInsuranceContact: ({ patientid, insType = 1 }: any) => apis.$get(
    '/patient-insurances',
    {
      params: {
        'filter[patientid]': patientid,
        'filter[insurancetype]': insType
      }
    }
  )
}
