import { setAuthConfig } from 'ui-common'
import '@/components'

const {
  VUE_APP_PATIENT_API_CLIENT_ID,
  VUE_APP_PATIENT_API_CLIENT_SECRET
} = process.env

setAuthConfig({
  clientId: +VUE_APP_PATIENT_API_CLIENT_ID,
  clientSecret: VUE_APP_PATIENT_API_CLIENT_SECRET
})

export { default as router } from '@/router'
export { default as vuetify } from './vuetify'
export { default as i18n } from './vue-i18n'
