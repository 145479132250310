var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.activate)?_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v(" First time user? ")]):_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_vm._v(" Forgot Password ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('common-form',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isValidForm = ref.isValidForm;
return [(_vm.activate)?_c('v-card-title',[_vm._v(" Setup Account ")]):_c('v-card-title',[_vm._v(" Reset Password ")]),_c('v-card-text',{staticClass:"pb-0"},[(_vm.activate)?_c('p',[_vm._v(" Enter the email address you provided us, and we'll send you instructions to complete activation. ")]):_c('p',[_vm._v(" Enter the email address you provided us, and we'll email instructions to help reset your password. ")]),_c('v-text-field',{key:_vm.dialog,attrs:{"label":"Email address","outlined":"","rules":[
            _vm.$validators.isValid(),
            _vm.$validators.isEmail()
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info","text":""},on:{"click":_vm.resetDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"info","text":"","disabled":!isValidForm},on:{"click":_vm.sendActivateLink}},[_vm._v(" Send ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }