<template>
  <v-dialog
    v-model="dialog"
    width="300"
  >
    <template #activator="{ on, attrs }">
      <a
        v-if="activate"
        v-bind="attrs"
        v-on="on"
      >
        First time user?
      </a>
      <a
        v-else
        v-bind="attrs"
        v-on="on"
      >
        Forgot Password
      </a>
    </template>

    <v-card>
      <common-form #default="{ isValidForm }">
        <v-card-title v-if="activate">
          Setup Account
        </v-card-title>
        <v-card-title v-else>
          Reset Password
        </v-card-title>

        <v-card-text class="pb-0">
          <p v-if="activate">
            Enter the email address you provided us,
            and we'll send you instructions to complete activation.
          </p>
          <p v-else>
            Enter the email address you provided us,
            and we'll email instructions to help reset your password.
          </p>

          <v-text-field
            :key="dialog"
            v-model="email"
            label="Email address"
            outlined
            :rules="[
              $validators.isValid(),
              $validators.isEmail()
            ]"
          />
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer />

          <v-btn
            color="info"
            text
            @click="resetDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="info"
            text
            :disabled="!isValidForm"
            @click="sendActivateLink"
          >
            Send
          </v-btn>
        </v-card-actions>
      </common-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import repositories from '@/repositories'
import { toastr } from 'ui-common'

export default {
  props: {
    activate: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const dialog = ref(false)
    const email = ref('')

    const resetDialog = () => {
      dialog.value = false
      email.value = ''
    }
    const sendActivateLink = async () => {
      try {
        if (props.activate) {
          await repositories.patients
            .requestActivate({ email: email.value })
        } else {
          await repositories.patients
            .requestResetPassword({ email: email.value })
        }
        toastr.s('We sent instructions to your email')
        resetDialog()
      } catch (err) {
        const message = err?.response?.data?.message
        if (message) {
          toastr.e(message)
        }
      }
    }

    return {
      dialog,
      email,
      resetDialog,
      sendActivateLink
    }
  }
}
</script>
