import { apis } from 'ui-common'
import moment from 'moment'
import { arraySerializer } from '@/utils/answer'

export default {
  submitPrevTreatments: ({
    qPage2Id,
    info: {
      patientid,
      userid,
      docid,
      hadSleepStudy,
      sleepStudyLoc,
      sleepStudyDate,
      hadCPAP,
      intolerances,
      otherIntolerance,
      otherTherapy,
      curDentalDevice,
      prevDentalDevice,
      otc,
      fabByDentist,
      dentistName,
      experience,
      currentCPAP,
      cpapNight,
      cpapHour,
      hadSurgery
    }
  }: any) => apis.$put(
    `/previous-treatments/${qPage2Id}`,
    {
      patientid,
      userid: userid || docid,
      docid,
      status: 1,

      polysomnographic: hadSleepStudy ? 1 : 0,
      sleepCenterNameText: sleepStudyLoc,
      sleepStudyOn: hadSleepStudy
        ? moment(sleepStudyDate).format('MM/DD/YYYY') : undefined,

      cpap: hadCPAP ? 'Yes' : 'No',
      intolerance: arraySerializer(intolerances
        .filter(({ value }: any) => value)
        .map(({ intoleranceid }: any) => intoleranceid)),
      otherIntolerance,
      otherTherapy,
      ddWearing: curDentalDevice ? 'Yes' : 'No',
      ddPrev: prevDentalDevice ? 'Yes' : 'No',
      ddOtc: otc ? 'Yes' : 'No',
      ddFab: fabByDentist ? 'Yes' : 'No',
      ddWho: dentistName,
      ddExperience: experience,
      curCpap: currentCPAP ? 'Yes' : 'No',
      nightsWearCpap: `${cpapNight}`,
      percentNightCpap: `${cpapHour}`,

      surgery: hadSurgery ? 'Yes' : 'No',

      isCompleted: true
    }
  )
}
