import { apis } from 'ui-common'

export default {
  getEpworthSleepinessScale: () => apis.$get(
    '/epworth-sleepiness-scale',
    {
      params: {
        status: 1,
        order: 'sortby'
      }
    }
  ),
  submitSleepTests: ({ info }: any) => apis.$post('/sleep-tests', info)
}
