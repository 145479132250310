import { VueI18nManager, translations as uiCommonTranslations } from 'ui-common'
import { merge } from 'lodash'
import { translations } from '@/constants'

const messages = merge(uiCommonTranslations, translations)

export default VueI18nManager.recreateInstance({
  locale: 'en',
  missing: (_, key) => key,
  messages
})
