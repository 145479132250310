import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'

import { auth, useRouter } from 'ui-common'
import authGuard from './auth-guard'

import { secondaryProfile } from '@/use/profile'
import { pages } from '@/constants'

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue')
})

routes.push({
  path: '/register',
  redirect: () => {
    const pageArr = Object.keys(pages.register)
    return '/register/' +
      pageArr[Math.min(
        secondaryProfile.value?.lastRegSect || 0,
        pageArr.length - 1
      )]
  }
})

routes.push({
  path: '/questionnaire',
  redirect: '/questionnaire/symptoms'
})

routes.push({
  path: '*',
  redirect: () => auth.$state.logged ? '/' : '/login'
})

const router = useRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes
    }
  ]
})
router.beforeEach(authGuard)

export default router
