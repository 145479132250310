import pages from './pages.json'
import states from './states.json'
import translations from './translations.json'

export const PageContactInfo = 1
export const PagePersonalInfo = 2
export const PageInsurance = 3
export const Page2ndInsurance = 4
export const PageEmployer = 5
export const PageContacts = 6

export const activeMedicareInsType = '7'
export const activeMedicareInsSubType = '1'

export {
  pages,
  states,
  translations
}
