import { ref, Ref } from '@vue/composition-api'
import repositories from '@/repositories'
import { primaryProfile } from './profile'

const qSections: Ref<any> = ref(null)

export const useQuestionnaire = () => {
  const loadingCompletedSections = ref(false)

  const getCompletedSections = async () => {
    loadingCompletedSections.value = true

    try {
      const { patientid } = primaryProfile.value

      const res = await repositories
        .patients.getQuestionnaireCompletedSections({ patientid })
      const {
        lastRegisteredSectionCompleted,
        symptomSectionCompleted,
        sleepTestSectionCompleted,
        previousTreatmentCompleted,
        healthHistorySectionCompleted,
        completedPercent,
        questionnaireCompleted
      } = res.data

      qSections.value = {
        registerCompleted: !!lastRegisteredSectionCompleted,
        symptoms: !!symptomSectionCompleted,
        epworth: !!sleepTestSectionCompleted,
        prevTreatments: !!previousTreatmentCompleted,
        history: !!healthHistorySectionCompleted,
        percent: completedPercent,
        questionnaireCompleted
      }
    } catch (err) {} finally {
      loadingCompletedSections.value = false
    }
  }

  return {
    qSections,
    loadingCompletedSections,
    getCompletedSections
  }
}
