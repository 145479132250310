import { auth } from 'ui-common'
import { NavigationGuard } from 'vue-router'

const authGuard: NavigationGuard = (to, from, next) => {
  const { logged } = auth.$state

  const noAuth: string[] = ['/login', '/reset-password', '/activate']
  const isNonAuthPage = !!noAuth.find(path => to.path.startsWith(path))

  if (logged && isNonAuthPage) {
    next('/')
  } else if (!logged && !isNonAuthPage) {
    next('/login')
  }
  next()
}

export default authGuard
