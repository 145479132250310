import { apis } from 'ui-common'

const PatientIdQueryParameter = 'patient_id'

export default {
  getQuestionnaireCompletedSections: ({ patientid }: any) => apis
    .$get(`/patients/${patientid}/questionnaire-completed-sections`),
  getEmailDuplication: ({ email, patientId }: any) => apis
    .$get(
      '/patients/check-email',
      { params: { email, [PatientIdQueryParameter]: patientId } }
    ),

  getActivateData: ({ id, hash }: any) => apis.$get(
    `/patients/get-activate-data/${id}`,
    { params: { hash } }
  ),
  sendAccessCode: ({ id, hash }: any) => apis.$post(
    '/patients/send-access-code',
    { recoverHash: hash, patientId: id }
  ),
  resetPassword: ({ email, accessCode, password }: any) => apis.$patch(
    '/patients/reset-password',
    { email, accessCode, password }
  ),

  requestActivate: ({ email }: any) => apis.$post(
    '/patients/send-instructions',
    { action: 'activate', link: 'activate?id=%s&hash=%s' },
    {
      headers: { 'x-patient-email': email }
    }
  ),
  requestResetPassword: ({ email }: any) => apis.$post(
    '/patients/send-instructions',
    { action: 'reset', link: 'reset-password?id=%s&hash=%s' },
    {
      headers: { 'x-patient-email': email }
    }
  )
}
