import { repositories } from 'ui-common'
import allergens from './allergens'
import complaints from './complaints'
import contacts from './contacts'
import epworth from './epworth'
import healthHistory from './healthHistory'
import intolerances from './intolerances'
import languages from './languages'
import medicalHistories from './medicalHistories'
import medications from './medications'
import patientInsurances from './patientInsurances'
import patients from './patients'
import previousSurgeries from './previousSurgeries'
import previousTreatments from './previousTreatments'
import profile from './profile'
import symptoms from './symptoms'
import thorntons from './thorntons'

export default {
  ...repositories,
  allergens,
  complaints,
  contacts,
  epworth,
  healthHistory,
  intolerances,
  languages,
  medicalHistories,
  medications,
  patientInsurances,
  patients,
  previousSurgeries,
  previousTreatments,
  profile,
  symptoms,
  thorntons
}
