import { apis } from 'ui-common'

const startsWithColumnsField = 'starts_with_columns'
const startsWithValuesField = 'starts_with_values'

export default {
  getContactCandidates: ({ keyword, docid }: any) =>
    apis.$get('/contacts', {
      params: {
        [startsWithColumnsField]: ['lastname', 'firstname'],
        [startsWithValuesField]: keyword,
        'filter[docid]': docid,
        'sort[lastname]': 'ASC'
      }
    }),
  getContactTypesByPatient: ({ patientid }: any) =>
    apis.$get(`/contacts/get-contact-types-by-patient/${patientid}`),
  getPatientContacts: ({ patientid }: any) =>
    apis.$get('/patient-contacts', {
      params: { 'filter[patientid]': patientid }
    }),
  deletePatientContact: ({ id }: any) =>
    apis.$delete(`/patient-contacts/${id}`),
  updatePatientContacts: ({ id, contact }: any) =>
    apis.$put(`/patient-contacts/${id}`, contact),
  createPatientContacts: ({ contact }: any) =>
    apis.$post('/patient-contacts', contact)
}
