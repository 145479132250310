import { apis } from 'ui-common'

export default {
  getProfile: () => apis.$get('/patients'),
  createProfile: (info: any) => apis.$post('/patients', info),
  updatePatientEmail: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/email`, info),
  updatePatientContactInfo: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/contact-info`, info),
  updatePatientPersonalInfo: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/personal-info`, info),
  updatePatientInsurance: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/insurance`, info),
  updatePatientSecondaryInsurance: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/secondary-insurance`, info),
  updatePatientEmployer: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/employer`, info),
  updatePatientContacts: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/contacts`, info),
  updatePatientQuestionnaire: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/questionnaire`, info),
  updatePatientLanguage: ({ patientid, ...info }: any) => apis
    .$patch(`/patients/${patientid}/language`, info),
  getPatientProfile: ({ patientid }: any) => apis
    .$get(`/patients/${patientid}`),
  updateEmail: ({ patientid, oldEmail }: any) => apis
    .$post(
      '/patients/send-updated-email-notification',
      {
        patientId: patientid,
        oldEmail,
        loginLink: 'login'
      }
    )
}
