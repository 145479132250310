var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$auth.$state.profile || _vm.loadingProfile)?_c('app-loading',{attrs:{"logo":"/img/logo.jpg","background":_vm.$vuetify.theme.themes.light.info,"logo-background":"#00447c"}}):_c('v-app',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"layout-default",attrs:{"dark":""}},[_c('v-app-bar',{staticClass:"elevation-0",class:_vm.offsetTop && 'v-app-bar--fill',attrs:{"dark":!_vm.offsetTop,"app":""}},[_c('v-container',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-active",attrs:{"icon":"","to":"/","exact":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Go to Home'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-active",attrs:{"icon":"","to":"/register"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Go to Registration'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-active",attrs:{"icon":"","to":"/questionnaire"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-question-outline")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Go to Questionnaire'))+" ")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-translate ")]),_vm._v(" "+_vm._s(_vm.$t(_vm.languageState.activeLang))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.languages),function(language,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeLanguage(language)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(language.title)))])],1)}),1)],1),_c('v-spacer'),_c('span',{staticClass:"font-weight-bold ml-3"},[_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v(" "+_vm._s(_vm.$t('Patient Portal'))+" - ")]),_c('span',[_vm._v(" "+_vm._s(_vm.primaryProfile.firstname)+" ")]),_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v(" "+_vm._s(_vm.primaryProfile.lastname)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"icon":""},on:{"click":_vm.$auth.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Logout'))+" ")])])],1)],1),_c('v-main',[_c('v-container',{staticClass:"my-md-8"},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"1120"}},[_c('pp-stepper'),_c('v-card-text',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }