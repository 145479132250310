import { apis } from 'ui-common'
import moment from 'moment'

export default {
  submitPrevSurgeries: ({
    patientid,
    date,
    surgery,
    surgeon
  }: any) => apis.$post(
    '/previous-surgeries',
    {
      patientid,
      surgeryDate: moment(date).format('MM/DD/YYYY'),
      surgery,
      surgeon,
      referenceId: 0
    }
  )
}
