<template>
  <v-stepper class="elevation-0">
    <v-stepper-header>
      <fragment
        v-for="(pageName, pageUID, pIndex) in pages.register"
        :key="pIndex"
      >
        <v-divider v-if="pIndex" />
        <v-stepper-step
          class="text-capitalize tapable"
          :class="{
            'no-cursor': (lastRegSect < pIndex) ||
              (pageUID === 'second-ins' &&
                secondaryProfile.hasPMIns !== 'Yes')
          }"
          :complete="pageID > pIndex - 1"
          :editable="pageID === pIndex"
          :step="pIndex + 1"
          @click="lastRegSect >= pIndex &&
            $utils.navigate(`/register/${pageUID}`)"
        >
          {{ $t(pageName) }}
        </v-stepper-step>
      </fragment>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import { computed } from '@vue/composition-api'
import { pages } from '@/constants'
import { useProfile } from '@/use/profile'

export default {
  props: {
    page: {
      type: String,
      default: pages.register[0]
    }
  },
  setup: (props) => {
    const { lastRegSect, secondaryProfile } = useProfile()
    const pageID = computed(() => {
      const idx = Object.keys(pages.register).indexOf(props.page)
      if (idx === -1) { return Object.keys(pages.register).length }
      return idx
    })

    return {
      pages,
      pageID,
      lastRegSect,
      secondaryProfile
    }
  }
}
</script>
