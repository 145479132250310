import Vue from 'vue'
import { upperFirst, camelCase } from 'lodash'

if (!require.context) {
  require.context = require('require-context')
}

const requireComponent = require.context(
  './', true, /\.vue$/
)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
      .replace('index', ''))
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})
