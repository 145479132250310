var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-form',{on:{"submit":_vm.resetPassword},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isValidForm = ref.isValidForm;
return [_c('v-card-text',{staticClass:"text-left pb-0"},[_c('v-text-field',{attrs:{"rules":[
        _vm.$validators.isValid(),
        _vm.$validators.isEmail()
      ],"outlined":"","color":"primary lighten-3","label":"Email","dense":""},model:{value:(_vm.emailModel),callback:function ($$v) {_vm.emailModel=$$v},expression:"emailModel"}}),_c('p',{staticClass:"pl-2"},[_vm._v(" We sent a text message to your phone number ending in -"+_vm._s(_vm.phone.slice(-2))+"."),_c('br'),_vm._v(" Please enter the code we sent you. ")]),_c('v-text-field',{attrs:{"rules":[function () { return !!_vm.accessCode || 'Access code is required'; }],"outlined":"","color":"primary lighten-3","label":"Access Code","dense":""},model:{value:(_vm.accessCode),callback:function ($$v) {_vm.accessCode=$$v},expression:"accessCode"}}),_c('v-text-field',{attrs:{"rules":[
        function () { return !!_vm.newpwd || 'Password is required'; },
        function () { return _vm.newpwd.length >= 8 ||
          'The password must be at least 8 characters'; }
      ],"type":"password","outlined":"","color":"primary lighten-3","label":"New Password","dense":""},model:{value:(_vm.newpwd),callback:function ($$v) {_vm.newpwd=$$v},expression:"newpwd"}}),_c('v-text-field',{attrs:{"rules":[function () { return _vm.confirmpwd ?
        (_vm.confirmpwd === _vm.newpwd ?
          true
          : 'Confirmation Password should be the same with new password') :
        'Confirmation password is required'; }
      ],"type":"password","outlined":"","color":"primary lighten-3","label":"Confirmation Password","dense":""},model:{value:(_vm.confirmpwd),callback:function ($$v) {_vm.confirmpwd=$$v},expression:"confirmpwd"}}),(_vm.errMessages)?_c('p',{staticClass:"error--text pl-2",domProps:{"innerHTML":_vm._s(_vm.errMessages)}}):_vm._e(),_c('v-checkbox',{staticClass:"ma-0 mb-2 ml-3",attrs:{"color":"primary lighten-3","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" I accept the ")]),_c('a',{staticClass:"ml-1 info--text",on:{"click":function($event){_vm.showUserAgreement = true}}},[_vm._v(" User Agreement ")])]},proxy:true}],null,true),model:{value:(_vm.acceptAgrement),callback:function ($$v) {_vm.acceptAgrement=$$v},expression:"acceptAgrement"}}),_c('dialog-user-agreement',{model:{value:(_vm.showUserAgreement),callback:function ($$v) {_vm.showUserAgreement=$$v},expression:"showUserAgreement"}}),_c('v-btn',{staticClass:"text-none ml-0 mb-4",attrs:{"text":"","small":"","to":"/login"}},[_c('v-icon',[_vm._v("mdi-chevron-double-left")]),_vm._v(" Return to login screen ")],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4 pt-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":!isValidForm || !_vm.acceptAgrement,"loading":_vm.resettingPassword}},[_vm._v(" "+_vm._s(_vm.activate ? 'Activate Account' : 'Reset Password')+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }