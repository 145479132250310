import { utils, toastr } from 'ui-common'
import repositories from '@/repositories'
import { ref, reactive, onMounted } from '@vue/composition-api'

export const useActivate = () => {
  const resettingPassword = ref(false)
  const resetPatientPassword = async ({
    email,
    accessCode,
    password,
    activate
  }: any) => {
    const message = activate
      ? 'You account has been activated'
      : 'Your password has been successfully reset'

    resettingPassword.value = true
    try {
      await repositories.patients.resetPassword({
        email,
        accessCode,
        password
      })

      toastr.s(message)
    } finally {
      resettingPassword.value = false
    }
  }

  const loadingPatientActivateData = ref(false)
  const getPatientActivateData = async ({ id, hash }: any) => {
    let email
    let phoneNumber

    loadingPatientActivateData.value = true
    try {
      const { data } = await repositories.patients
        .getActivateData({ id, hash })
      email = data.email
      phoneNumber = data.cellphone
    } catch (err) {
      utils.navigate('/login')
      throw err
    } finally {
      loadingPatientActivateData.value = false
    }

    return {
      email,
      phoneNumber
    }
  }

  const sendPatientAccessCode = async ({ id, hash }: any) => {
    try {
      await repositories.patients.sendAccessCode({ id, hash })
      toastr.s('Access code is sent to your phone')
    } catch (err) {}
  }

  return {
    resettingPassword,
    resetPatientPassword,

    loadingPatientActivateData,
    getPatientActivateData,

    sendPatientAccessCode
  }
}

export const useActivationPage = ({ context }: any) => {
  const { root } = context
  const paramInfo = reactive({ id: null, hash: null })
  const activationData = reactive({
    email: '',
    phoneNumber: ''
  })
  const succeedVerify = ref(false)
  const {
    loadingPatientActivateData,
    sendPatientAccessCode,
    getPatientActivateData
  } = useActivate()

  onMounted(async () => {
    const { id, hash } = root.$route.query

    if (!id || !hash) {
      utils.navigate('/login')
    }

    paramInfo.id = id
    paramInfo.hash = hash
    root.$router.replace({ query: {} })

    try {
      const data = await getPatientActivateData({ id, hash })
      activationData.email = data.email
      activationData.phoneNumber = data.phoneNumber

      succeedVerify.value = true
      sendPatientAccessCode({ id, hash })
    } catch (err) {
      const { response } = err as any

      if (response?.status === 403 || response?.status === 422) {
        switch (response?.data?.message) {
          case 'recover_time_expired':
            toastr.e('The activation link has expired')
            break
          case 'access_code_expired':
            toastr.e('The access code has expired')
            break
          default:
            toastr.e('The activation link is invalid')
            break
        }
      }
    }
  })

  return {
    activationData,
    paramInfo,
    succeedVerify,
    loadingPatientActivateData
  }
}
