<template>
  <app-loading
    v-if="!$auth.$state.profile || loadingProfile"
    logo="/img/logo.jpg"
    :background="$vuetify.theme.themes.light.info"
    logo-background="#00447c"
  />
  <v-app
    v-else
    v-scroll="onScroll"
    class="layout-default"
    dark
  >
    <v-app-bar
      class="elevation-0"
      :class="offsetTop && 'v-app-bar--fill'"
      :dark="!offsetTop"
      app
    >
      <v-container class="d-flex align-center">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="no-active"
              icon
              to="/"
              exact
              v-on="on"
            >
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </template>

          <span>
            {{ $t('Go to Home') }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="no-active"
              icon
              to="/register"
              v-on="on"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
          </template>

          <span>
            {{ $t('Go to Registration') }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="no-active"
              icon
              to="/questionnaire"
              v-on="on"
            >
              <v-icon>mdi-comment-question-outline</v-icon>
            </v-btn>
          </template>

          <span>
            {{ $t('Go to Questionnaire') }}
          </span>
        </v-tooltip>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize"
              v-bind="attrs"
              text
              v-on="on"
            >
              <v-icon left>
                mdi-translate
              </v-icon>
              {{ $t(languageState.activeLang) }}
              <v-icon
                small
                right
              >
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(language, index) in languages"
              :key="index"
              @click="changeLanguage(language)"
            >
              <v-list-item-title>{{ $t(language.title) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer />
        <span class="font-weight-bold ml-3">
          <span class="d-none d-md-inline-block">
            {{ $t('Patient Portal') }} -
          </span>
          <span>
            {{ primaryProfile.firstname }}
          </span>
          <span class="d-none d-md-inline-block">
            {{ primaryProfile.lastname }}
          </span>
        </span>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="ml-3"
              icon
              v-on="on"
              @click="$auth.logout"
            >
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>

          <span>
            {{ $t('Logout') }}
          </span>
        </v-tooltip>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container class="my-md-8">
        <v-card
          class="mx-auto"
          width="1120"
        >
          <pp-stepper />

          <v-card-text>
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view />
            </transition>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { reactive, ref, computed, onMounted } from '@vue/composition-api'
import { useProfile } from '@/use/profile'
import { useLanguage } from '@/use/language'
import { toastr, VueI18nManager } from 'ui-common'

export default {
  setup: (_, { root }) => {
    const {
      loadingProfile,
      getProfile,
      primaryProfile,
      secondaryProfile,
      updateSecondaryProfile
    } = useProfile()

    const {
      defaultLanguage,
      languages,
      getLanguages
    } = useLanguage()

    const languageState = reactive({
      activeLang: defaultLanguage
    })

    const changeLanguage = async (language) => {
      await updateSecondaryProfile({
        info: {
          languageId: language.id
        }
      })

      languageState.activeLang = language.title
      root.$i18n.locale = language.code
      VueI18nManager.changeLocale(language.code)
      toastr.s(root.$i18n.t('Language successfully changed.'))
    }

    const setCurrentLanguage = () => {
      for (const language of languages.value) {
        if (language.id === secondaryProfile.value.language.id) {
          languageState.activeLang = language.title
          root.$i18n.locale = language.code
          VueI18nManager.changeLocale(language.code)
          break
        }
      }
    }

    const offsetTop = ref(0)
    const onScroll = () => {
      offsetTop.value = pageYOffset
    }
    const pageName = computed(() =>
      root.$route.path.replace('/', '') || 'intro')

    onMounted(async () => {
      await getLanguages()
      await getProfile()
      setCurrentLanguage()
    })

    return {
      languages,
      languageState,

      changeLanguage,
      offsetTop,
      onScroll,
      pageName,

      primaryProfile,
      loadingProfile
    }
  }
}
</script>

<style lang="scss">
.layout-default {
  .v-application {
    &--wrap {
      background-color: aliceblue;

      > main.v-main {
        z-index: 1;
      }

      &:before {
        z-index: 0;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 350px;

        background-image: url('/img/sleep_couple.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      &:after {
        z-index: 0;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 350px;

        background-image: linear-gradient(to right, #0054D080, #01A09080);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .v-app-bar {
        background-color: transparent !important;
        border-bottom: 1px solid #fff4;
        transition: all .5s;

        &--fill {
          background-color: #FFFE !important;
          border-bottom-color: #3334;
        }
      }
    }
  }
}
</style>
