import { ref, Ref } from '@vue/composition-api'
import repositories from '@/repositories'
import { toastr, VueI18nManager } from 'ui-common'

export const useLanguage = () => {
  type Language = {
    id: number;
    title: string;
    code: string;
  }

  const defaultLanguage = 'English'
  const languages: Ref<Language[]> = ref([])

  const getLanguages = async () => {
    let languagesResponse

    try {
      languagesResponse = await repositories.languages.getLanguages()
    } catch (e: any) {
      const message = VueI18nManager
        .getInstance()
        .t('Unable to load available languages list.')
        .toString()
      toastr.e(message)
      return
    }

    languages.value = languagesResponse.data
  }

  return {
    defaultLanguage,
    languages,

    getLanguages
  }
}
