<template>
  <pp-stepper-register
    v-if="page.type === 'register'"
    :page="page.name"
  />
  <pp-stepper-questionnaire
    v-else-if="page.type === 'questionnaire'"
    :page="page.name"
  />
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  setup: (_, context) => {
    const { root } = context
    const page = computed(() => {
      const pageInfo = root.$route
        .path.split('/').filter(d => d)

      return {
        type: pageInfo[0],
        name: pageInfo[1]
      }
    })

    return {
      page
    }
  }
}
</script>
