<template>
  <common-form
    #default="{ isValidForm }"
    @submit="resetPassword"
  >
    <v-card-text class="text-left pb-0">
      <v-text-field
        v-model="emailModel"
        :rules="[
          $validators.isValid(),
          $validators.isEmail()
        ]"
        outlined
        color="primary lighten-3"
        label="Email"
        dense
      />
      <p class="pl-2">
        We sent a text message to your phone number ending in
        -{{ phone.slice(-2) }}.<br>
        Please enter the code we sent you.
      </p>
      <v-text-field
        v-model="accessCode"
        :rules="[() => !!accessCode || 'Access code is required']"
        outlined
        color="primary lighten-3"
        label="Access Code"
        dense
      />
      <v-text-field
        v-model="newpwd"
        :rules="[
          () => !!newpwd || 'Password is required',
          () => newpwd.length >= 8 ||
            'The password must be at least 8 characters'
        ]"
        type="password"
        outlined
        color="primary lighten-3"
        label="New Password"
        dense
      />
      <v-text-field
        v-model="confirmpwd"
        :rules="[() => confirmpwd ?
          (confirmpwd === newpwd ?
            true
            : 'Confirmation Password should be the same with new password') :
          'Confirmation password is required'
        ]"
        type="password"
        outlined
        color="primary lighten-3"
        label="Confirmation Password"
        dense
      />
      <p
        v-if="errMessages"
        class="error--text pl-2"
        v-html="errMessages"
      />
      <v-checkbox
        v-model="acceptAgrement"
        class="ma-0 mb-2 ml-3"
        color="primary lighten-3"
        hide-details
      >
        <template #label>
          <span>
            I accept the
          </span>
          <a
            class="ml-1 info--text"
            @click="showUserAgreement = true"
          >
            User Agreement
          </a>
        </template>
      </v-checkbox>
      <dialog-user-agreement v-model="showUserAgreement" />
      <v-btn
        class="text-none ml-0 mb-4"
        text
        small
        to="/login"
      >
        <v-icon>mdi-chevron-double-left</v-icon>
        Return to login screen
      </v-btn>
    </v-card-text>

    <v-divider />

    <v-card-actions class="pa-4 pt-2">
      <v-spacer />

      <v-btn
        type="submit"
        color="primary"
        :disabled="!isValidForm || !acceptAgrement"
        :loading="resettingPassword"
      >
        {{ activate ? 'Activate Account' : 'Reset Password' }}
      </v-btn>
    </v-card-actions>
  </common-form>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { auth } from 'ui-common'
import { useActivate } from '@/use/activate'

export default {
  props: {
    activate: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    hash: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    }
  },
  setup: (props, context) => {
    const { emit } = context
    const acceptAgrement = ref(false)
    const errMessages = ref('')

    const accessCode = ref('')
    const newpwd = ref('')
    const confirmpwd = ref('')

    const {
      resettingPassword,
      resetPatientPassword
    } = useActivate()

    const showUserAgreement = ref(false)

    const emailModel = computed({
      get: () => props.email,
      set: val => emit('update:email', val)
    })

    const resetPassword = async () => {
      const data = {
        email: props.email,
        accessCode: accessCode.value,
        password: newpwd.value,
        activate: props.activate
      }

      try {
        await resetPatientPassword(data)
        auth.login({
          username: props.email,
          password: newpwd.value
        })
      } catch (err) {
        const errLog = err?.response

        if (errLog?.status === 422) {
          errMessages.value = (errLog?.data?.errors || [])
            .map(({ message }) => message).join('<br>')
        } else if (errLog?.status === 404) {
          errMessages.value = 'Invalid Activation Code!'
        }
      }
    }

    return {
      acceptAgrement,
      errMessages,

      emailModel,
      accessCode,
      newpwd,
      confirmpwd,
      resetPassword,
      resettingPassword,

      showUserAgreement
    }
  }
}
</script>
