<template>
  <v-card v-bind="$attrs">
    <v-card-text v-if="sliderOnly || totalLen < 20">
      <v-slider
        :value="value"
        :min="fields.length
          ? (dontKnow ? -1 : 0)
          : (dontKnow ? min - 1 : min)"
        :max="(fields.length ? fields.length - 1 : max)"
        :thumb-label="fields.length
          ? false
          : ((dontKnow && (value === (min - 1))) ? false : 'always')"
        :always-dirty="fields.length
          ? (dontKnow ? (value === -1 ? false : true) : true)
          : (dontKnow ? (value === min - 1 ? false : true) : true)"
        :tick-labels="fields.length
          ? (dontKnow ? [`Don't know`].concat(fields) : fields)
          : []"
        :disabled="disabled"
        ticks="always"
        hide-details
        @change="value => $emit('input', value)"
      />
      <v-subheader class="pl-3">
        <span>
          {{ title }}
          <span class="primary--text">
            {{ fields.length ? '' : (value==min-1 ? ` : Don't know` : '') }}
          </span>
        </span>
      </v-subheader>
    </v-card-text>
    <v-card-text v-else>
      <v-select
        :value="value"
        :label="title"
        :items="fields.map((val, idx) => ({ label: val, value: idx }))"
        item-text="label"
        item-value="value"
        :disabled="disabled"
        outlined
        hide-details
        @change="value => $emit('input', value)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '0'
    },
    title: {
      type: String,
      default: ''
    },
    dontKnow: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    min: {
      type: [String, Number],
      default: '0'
    },
    max: {
      type: [String, Number],
      default: '100'
    },
    disabled: {
      type: Boolean,
      defualt: false
    },
    sliderOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalLen () {
      return this.fields
        .reduce((acc, cur) => acc + `${cur}`.split('').length, 0)
    }
  }
}
</script>
